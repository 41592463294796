import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GroupsIcon from '@mui/icons-material/Groups';
import StarIcon from '@mui/icons-material/Star';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { BsTiktok } from "react-icons/bs";


const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState('/');

  // Atualiza o valor baseado na rota atual
  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(newValue);
      }}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#028069',
        padding: 0, // Remove padding interno
      }}
    >
      <BottomNavigationAction
        label="Tinder"
        value="/tinder"
        icon={<WhatshotIcon />}
        sx={{
          color: value === '/tinder' ? '#FFF200' : '#BCBCBE', // Cor ativa e inativa
          '&.Mui-selected': {
            color: '#FFFFFF', // Garante que o ícone ativo fique amarelo
          },
        }}
      />

      <BottomNavigationAction
        label="Tiktok"
        value="/tiktok"
        icon={<BsTiktok sx={{ fontSize: '1.8rem' }} />} // Reduz tamanho do ícone
        sx={{
          color: value === '/tiktok' ? '#FFF200' : '#BCBCBE',
          minWidth: 70, // Reduz a largura mínima
          '&.Mui-selected': {
            color: '#FFFFFF',
          },
          '@media (max-width: 400px)': {
            fontSize: '1.0rem', // Reduz tamanho da fonte
          },
        }}
      />


      <BottomNavigationAction
        label="WhatsApp"
        value="/whatsapp"
        icon={<WhatsAppIcon />}
        sx={{
          color: value === '/whatsapp' ? '#FFF200' : '#BCBCBE', // Cor ativa e inativa
          '&.Mui-selected': {
            color: '#FFFFFF', // Garante que o ícone ativo fique amarelo
          },
        }}
      />

      <BottomNavigationAction
        label="Conteudos"
        value="/conteudos"
        icon={
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.2rem', // Tamanho do "+18"
              color: 'inherit', // Herdar cor do estado (ativo/inativo)
            }}
          >
            +18
          </Typography>
        }
        sx={{
          color: value === '/conteudos' ? '#FFF200' : '#BCBCBE', // Cor ativa e inativa
          '&.Mui-selected': {
            color: '#FFFFFF', // Garante que o ícone ativo fique branco
          },
        }}
      />

      <BottomNavigationAction
        label="Games"
        value="/games"
        icon={<SportsEsportsIcon />}
        sx={{
          color: value === '/games' ? '#FFF200' : '#BCBCBE', // Cor ativa e inativa
          '&.Mui-selected': {
            color: '#FFFFFF', // Garante que o ícone ativo fique branco
          },
        }}
      />



    </BottomNavigation>
  );
};

export default Footer;
