import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Catalogo = () => {
  const [sections, setSections] = useState([]); // Estado para armazenar as seções
  const [loading, setLoading] = useState(true); // Estado para indicar o carregamento
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [selectedVideo, setSelectedVideo] = useState(null); // Estado para armazenar o vídeo selecionado
  const [userPlan, setUserPlan] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedVideo(null); // Pausar o vídeo removendo a URL
  };

  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario } = data.usuario;
          setUserPlan(plano_do_usuario);
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  useEffect(() => {
    fetchUserPlan();
  }, []);

  // Fetch para carregar seções da API
  useEffect(() => {
    const loadSections = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://painelbackend.whatsapchat.com.br/api/sections');
        if (!response.ok) {
          throw new Error(`Erro ao carregar seções: ${response.status}`);
        }
        const data = await response.json();
        setSections(data); // Atribui os dados da API ao estado
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadSections();
  }, []);

  if (loading) {
    return <Typography>Carregando...</Typography>; // Mostra enquanto carrega
  }

  if (error) {
    return <Typography color="error">Erro: {error}</Typography>; // Mostra o erro, se houver
  }

  // Função para redirecionar para o link do item ou exibir o vídeo no iframe
  const handleRedirect = (url, isVideo) => {
    if (isVideo) {
      if (userPlan === 'Basico') {
        setSelectedVideo(url);
        setTimeout(() => {
          setOpenDialog(true);
        }, 10000);
      } else {
        setSelectedVideo(url);
      }
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EDF2FA',
        color: '#004D40',
        minHeight: '100vh',
        p: 2,
        pb: 8,
      }}
    >
      {sections.map((section, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2 }}>
            {section.items.map((item) => {
              const isVideo = section.title === 'Videos';
              return (
                <Box key={item.id} sx={{ minWidth: 200 }}>
                  <Card
                    sx={{
                      backgroundColor: '#028069',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      ':hover': { transform: 'scale(1.05)' },
                      transition: 'transform 0.3s',
                    }}
                    onClick={() => handleRedirect(item.url_redirecionamento, isVideo)}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={item.image}
                      alt={item.title}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', textAlign: 'center', color: '#EDF2FA' }}
                      >
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}

      {selectedVideo && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#EDF2FA',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={selectedVideo}
            title="Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              backgroundColor: '#EDF2FA',
            }}
          ></iframe>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        disableBackdropClick
        PaperProps={{
          sx: {
            backgroundColor: '#222B36',
            color: '#FFF',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#028069',
          }}
        >
          Acesso Restrito
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            Compre o plano <b>Pro</b>. Adquira o plano para desbloquear todas as funcionalidades.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#028069',
              '&:hover': {
                backgroundColor: '#028069',
              },
              animation: 'pulse 1.5s ease-in-out infinite',
              mt: 2,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            onClick={async () => {
              try {
                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento');
                const data = await response.json();
                if (data && data.link) {
                  window.open(data.link, '_blank');
                } else {
                  console.error('Link de pagamento não encontrado.');
                }
              } catch (error) {
                console.error('Erro ao buscar o link de pagamento:', error);
              }
            }}
          >
            CLIQUE AQUI PARA SER VIP
            <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Catalogo;
