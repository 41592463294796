import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Games = () => {
  const [games, setGames] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  // Função para buscar os jogos da API
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch('https://painelbackend.whatsapchat.com.br/api/games');
        if (!response.ok) {
          throw new Error('Erro ao carregar jogos');
        }
        const data = await response.json();
        setGames(data);
      } catch (error) {
        console.error('Erro ao buscar jogos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const handleGameClick = (game) => {
    if (game.isPro) {
      setSelectedGame(game);
      setOpenDialog(true);
    } else {
      setSelectedGame(game);
      setIframeVisible(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGame(null);
  };

  const handleCloseIframe = () => {
    setIframeVisible(false);
    setSelectedGame(null);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: 2,
        }}
      >
        <SportsEsportsIcon sx={{ color: '#028069' }} />
        <Typography variant="h6" sx={{ color: '#020202' }}>
          Jogos Disponíveis
        </Typography>
      </Box>

      {loading ? (
        <Typography>Carregando jogos...</Typography>
      ) : (
        <Grid container spacing={2}>
          {games.map((game) => (
            <Grid item xs={4} key={game.id}>
              <Box
                onClick={() => handleGameClick(game)}
                sx={{
                  position: 'relative',
                  backgroundColor: 'transparent',
                  height: 120,
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={game.logoDoGame}
                  alt={`Jogo ${game.id}`}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    filter: game.isPro ? 'grayscale(100%)' : 'none',
                  }}
                />
                {game.isPro && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 7,
                      right: 7,
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      borderRadius: '50%',
                      width: 24,
                      height: 24,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EmojiEventsIcon sx={{ color: '#FFD700', fontSize: 20 }} />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {iframeVisible && selectedGame && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#fff' }}>
          <iframe
            src={selectedGame.linkGame}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title={`Jogo ${selectedGame.id}`}
          />
          <Button
            variant="contained"
            onClick={handleCloseIframe}
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              backgroundColor: '#FFF200',
              '&:hover': {
                backgroundColor: '#FFF200',
              },
            }}
          >
            Fechar
          </Button>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            backgroundColor: '#222B36',
            color: '#FFF',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FFF200',
          }}
        >
          Acesso Restrito
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            Este jogo é exclusivo para membros do plano <b>Pro</b>. Adquira o plano para desbloquear este jogo!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFF200',
              '&:hover': {
                backgroundColor: '#FFF200',
              },
              animation: 'pulse 1.5s ease-in-out infinite',
              mt: 2,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            onClick={() => alert('Redirecionando para o plano Pro...')}
          >
            CLIQUE AQUI PARA SER VIP
            <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Games;
