import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import StorysDisponiveis from '../components/Storys';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';


const Tinder = () => {
    const [currentProfile, setCurrentProfile] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openChatIframe, setOpenChatIframe] = useState(false); // Estado para controlar o iframe
    const [selectedChatLink, setSelectedChatLink] = useState(""); // Link dinâmico para o iframe
    const [openDialogPlano, setOpenDialogPlano] = useState(false);
    const [userPlan, setUserPlan] = useState(null);



    useEffect(() => {
        if (!openDialog) {
            const fetchTinder = async () => {
                try {
                    const response = await fetch('https://painelbackend.whatsapchat.com.br/api/tinder');
                    const data = await response.json();

                    const shuffleProfiles = (profiles) => {
                        return profiles.sort(() => Math.random() - 0.5);
                    };

                    setProfiles(shuffleProfiles(data));
                } catch (error) {
                    console.error('Erro ao buscar os perfis:', error);
                }
            };
            fetchTinder();
        }
    }, [openDialog]);



    const fetchUserPlan = () => {
        const email = localStorage.getItem('userEmail');
        if (email) {
            fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
                .then((response) => response.json())
                .then((data) => {
                    const { plano_do_usuario } = data.usuario;
                    setUserPlan(plano_do_usuario);
                })
                .catch((err) => console.error('Erro ao buscar o usuário:', err));
        }
    };

    useEffect(() => {
        fetchUserPlan();
    }, []);

    const handleCloseDialog = () => setOpenDialogPlano(false);

    const handleLike = () => {
        if (currentProfile < profiles.length - 1) {
            setCurrentProfile(currentProfile + 1);
        } else {
            setCurrentProfile(0);
        }
    };

    const handleReplay = () => {
        if (currentProfile > 0) {
            setCurrentProfile(currentProfile - 1);
        } else {
            setCurrentProfile(profiles.length - 1);
        }
    };

    return (
        <Box sx={{ backgroundColor: '#EDF2FA', height: '100vh', paddingTop: 1 }}>
            <Box sx={{ mb: 2 }}>
                <StorysDisponiveis />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '85vh',
                    backgroundColor: '#EDF2FA',
                }}
            >
                <Box
                    sx={{
                        width: 400,
                        height: 570,
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                        backgroundColor: '#EDF2FA',
                        position: 'relative',
                    }}
                >
                    {profiles.length > 0 && (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '60%',
                                    background: `url(${profiles[currentProfile].image}) center/contain no-repeat`,
                                }}
                            ></Box>

                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{ fontSize: '1.5rem', color: '#020202' }}
                                >
                                    {profiles[currentProfile].name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mt: 1, fontSize: '1.2rem', color: '#020202' }}
                                >
                                    {profiles[currentProfile].description}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 25,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    backdropFilter: 'blur(10px)',
                                    borderRadius: '10px',
                                    padding: '10px 0',
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                    }}
                                    onClick={handleReplay}
                                >
                                    <ReplayIcon sx={{ color: '#FFA500', fontSize: 28 }} />
                                </IconButton>

                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                    }}
                                    onClick={handleLike}
                                >
                                    <CloseIcon sx={{ color: '#FF3E4D', fontSize: 28 }} />
                                </IconButton>

                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                    }}
                                    onClick={() => {
                                        if (profiles[currentProfile]?.matchTinder) {
                                            setOpenDialog(true); // Abre o Dialog se matchTinder for true
                                        } else {
                                            // Passa para o próximo perfil
                                            if (currentProfile < profiles.length - 1) {
                                                setCurrentProfile(currentProfile + 1);
                                            } else {
                                                setCurrentProfile(0); // Retorna ao primeiro perfil se for o último
                                            }
                                        }
                                    }}
                                >
                                    <FavoriteIcon sx={{ color: '#4CAF50', fontSize: 28 }} />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                    }}
                                    onClick={() => {
                                        if (profiles[currentProfile]?.matchTinder) {
                                            setOpenDialog(true); // Abre o Dialog se matchTinder for true
                                        } else {
                                            // Passa para o próximo perfil
                                            if (currentProfile < profiles.length - 1) {
                                                setCurrentProfile(currentProfile + 1);
                                            } else {
                                                setCurrentProfile(0); // Retorna ao primeiro perfil se for o último
                                            }
                                        }
                                    }}
                                >
                                    <StarIcon sx={{ color: '#00BFFF', fontSize: 28 }} />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            {/* Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        padding: 3,
                        backgroundColor: '#333',
                        color: '#fff',
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Você deu Match
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Box
                            component="img"
                            src="https://via.placeholder.com/100"
                            alt="Your profile"
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: '50%',
                                border: '2px solid #fff',
                            }}
                        />
                        <Box
                            component="img"
                            src={profiles[currentProfile]?.image}
                            alt="Matched profile"
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: '50%',
                                border: '2px solid #fff',
                            }}
                        />
                    </Box>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {profiles[currentProfile]?.name}, Gostou de você e quer fuder
                    </Typography>
                    <Button
                        onClick={() => {
                            if (userPlan === "Basico") {
                                setOpenDialog(false); // Fecha o dialog de Match
                                setOpenDialogPlano(true); // Abre o dialog do plano
                            } else {
                                setSelectedChatLink(profiles[currentProfile]?.typebot || ""); // Define o link do Typebot
                                setOpenDialog(false); // Fecha o dialog de Match
                                setOpenChatIframe(true); // Abre o iframe
                            }
                        }}
                        variant="contained"
                        sx={{
                            backgroundColor: '#FF3E4D',
                            mt: 3,
                            textTransform: 'none',
                        }}
                    >
                        Continue
                    </Button>
                </DialogContent>
            </Dialog>

            {openChatIframe && selectedChatLink && (
                <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#fff' }}>
                    <iframe
                        src={selectedChatLink}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                        title="Chat"
                    />
                </Box>
            )}

            <Dialog
                open={openDialogPlano}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        backgroundColor: '#222B36',
                        color: '#FFF',
                        borderRadius: 2,
                        p: 2,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#028069',
                    }}
                >
                    Acesso Restrito
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign="center">
                        VOCÊ AINDA NÃO É VIP!  ADQUIRA O PLANO VIP  PARA TER ACESSO TOTAL AO APP
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#028069',
                            '&:hover': {
                                backgroundColor: '#028069',
                            },
                            animation: 'pulse 1.5s ease-in-out infinite',
                            mt: 2,
                            textTransform: 'none',
                            fontWeight: 'bold',
                        }}
                        onClick={async () => {
                            try {
                                // Fazendo a requisição para o endpoint de link de pagamento
                                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento');
                                const data = await response.json();

                                // Verificando se a resposta contém o link de pagamento
                                if (data && data.link) {
                                    // Redirecionando para o link de pagamento
                                    window.open(data.link, '_blank');
                                    // window.location.href = data.link;
                                } else {
                                    console.error('Link de pagamento não encontrado.');
                                }
                            } catch (error) {
                                console.error('Erro ao buscar o link de pagamento:', error);
                            }
                        }}
                    >
                        CLIQUE AQUI PARA SER VIP
                        <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default Tinder;
