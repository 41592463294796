import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Avatar,
    Grid,
    IconButton,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
const TikTok = () => {
    const [userPlan, setUserPlan] = useState(null);
    const [ftPerfil, setFtPerfil] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [watchedVideos, setWatchedVideos] = useState(0);
    const [videoList, setVideoList] = useState([]);
    const navigate = useNavigate();
    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [liked, setLiked] = useState(false);
    const [likedVideos, setLikedVideos] = useState({}); // Estado para rastrear curtidas por vídeo
    const videoRefs = useRef([]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/'); // Redireciona para a URL "/home"
    };

    const handleWhatsAppShare = () => {
        const currentURL = window.location.href; // URL atual da página
        const message = encodeURIComponent(`Confira esse vídeo incrível! ${currentURL}`); // Texto + URL
        const url = `https://wa.me/?text=${message}`;
        window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
    };

    const handleInstagramShare = () => {
        const currentURL = window.location.href; // URL atual da página
        const encodedURL = encodeURIComponent(currentURL); // Codifica a URL para segurança
        const url = `https://www.instagram.com/direct/inbox/?text=${encodedURL}`; // URL de compartilhamento
        window.open(url, '_blank'); // Abre o Instagram em uma nova aba
    };



    const handleScroll = () => {
        videoRefs.current.forEach((video, index) => {
            if (!video) return;

            const rect = video.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom > 0;

            if (isVisible) {
                video.play().catch(() => {
                    console.error('Falha ao reproduzir o vídeo.');
                });

                if (!video.dataset.watched) {
                    video.dataset.watched = true;
                    setWatchedVideos((prev) => {
                        const newCount = prev + 1;
                        if (newCount === 21 && userPlan === 'Basico') {
                            setOpenDialog(true);
                        }
                        return newCount;
                    });
                }
            } else {
                video.pause();
            }
        });
    };

    const fetchUserPlan = () => {
        const email = localStorage.getItem('userEmail');
        if (email) {
            fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
                .then((response) => response.json())
                .then((data) => {
                    const { plano_do_usuario } = data.usuario;
                    setUserPlan(plano_do_usuario);
                })
                .catch((err) => console.error('Erro ao buscar o usuário:', err));
        }
    };

    const fetchUserFtPerfil = () => {
        const email = localStorage.getItem('userEmail');
        if (email) {
            fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
                .then((response) => response.json())
                .then((data) => {
                    const { perfil_foto } = data.usuario;
                    setFtPerfil(perfil_foto);
                    console.log({ ft: perfil_foto })
                })
                .catch((err) => console.error('Erro ao buscar o usuário:', err));
        }
    };




    const handleOpenShareDialog = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };



    const fetchVideos = () => {
        fetch('https://painelbackend.whatsapchat.com.br/api/videos-tiktok')
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    setVideoList(data.map((item) => item.video_url));
                } else {
                    console.error('Resposta da API não está no formato esperado.');
                }
            })
            .catch((err) => console.error('Erro ao buscar vídeos:', err));
    };

    useEffect(() => {
        const scrollContainer = document.getElementById('scroll-container');
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        handleScroll();

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [userPlan, videoList]);

    useEffect(() => {
        fetchUserFtPerfil();
        fetchUserPlan();
        fetchVideos();
    }, []);

    const handleVideoError = (index) => {
        console.error(`Erro ao carregar o vídeo ${index + 1}.`);
    };


    const toggleLike = (index) => {
        setLikedVideos((prev) => ({
            ...prev,
            [index]: !prev[index], // Alterna o estado de curtida para o vídeo específico
        }));
    };


    return (
        <Box
            id="scroll-container"
            sx={{
                height: '100vh',
                overflowY: 'scroll',
                scrollSnapType: 'y mandatory',
                backgroundColor: '#000',
                color: '#fff',
                WebkitOverflowScrolling: 'touch',
            }}
        >
            {videoList.map((video, index) => (
                <Box
                    key={index}
                    sx={{
                        height: '100vh',
                        scrollSnapAlign: 'start',
                        position: 'relative',
                    }}
                >
                    <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        src={video}
                        loop
                        playsInline
                        muted
                        onError={() => handleVideoError(index)}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    {/* Ícones no lado direito */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '42%',
                            right: 16,
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Avatar
                            alt="User Avatar"
                            src={ftPerfil}
                            sx={{ width: 56, height: 56, mb: 1 }}
                        />
                        <IconButton onClick={() => toggleLike(index)}> {/* Corrigido de videoIndex para index */}
                            <FavoriteIcon
                                fontSize="large"
                                sx={{ color: likedVideos[index] ? 'red' : '#fff' }} // Corrigido de videoIndex para index
                            />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: '#fff', mb: 1 }}>
                            98,5 mil
                        </Typography>
                        <IconButton>
                            <CommentIcon fontSize="large" sx={{ color: '#fff' }} /> {/* Cor branca aplicada */}
                        </IconButton>
                        <Typography variant="body2" sx={{ color: '#fff', mb: 1 }}>
                            2.694
                        </Typography>
                        <IconButton onClick={handleOpenShareDialog}>
                            <ShareIcon fontSize="large" sx={{ color: '#fff' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                            6.876
                        </Typography>
                    </Box>


                </Box>
            ))}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        backgroundColor: '#222B36',
                        color: '#FFF',
                        borderRadius: 2,
                        p: 2,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#028069',
                    }}
                >
                    Acesso Restrito
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign="center">
                        VOCÊ AINDA NÃO É <b>VIP!</b>. ADQUIRA O PLANO VIP  PARA TER ACESSO TOTAL AO APP
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#028069',
                            '&:hover': {
                                backgroundColor: '#028069',
                            },
                            animation: 'pulse 1.5s ease-in-out infinite',
                            mt: 2,
                            textTransform: 'none',
                            fontWeight: 'bold',
                        }}
                        onClick={async () => {
                            try {
                                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento');
                                const data = await response.json();
                                if (data && data.link) {
                                    window.open(data.link, '_blank');
                                } else {
                                    console.error('Link de pagamento não encontrado..');
                                }
                            } catch (error) {
                                console.error('Erro ao buscar o link de pagamento:', error);
                            }
                        }}
                    >
                        CLIQUE AQUI PARA SER VIP
                        <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openShareDialog}
                onClose={handleCloseShareDialog}
                PaperProps={{
                    sx: {
                        position: 'fixed',
                        bottom: 0,
                        m: 0,
                        width: '100%',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        boxShadow: '0px -3px 10px rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                <DialogContent sx={{ p: 4 }}>
                    <Typography
                        variant="h6"
                        sx={{ textAlign: 'center', mb: 6, fontWeight: 'bold' }}
                    >
                        Enviar para
                    </Typography>


                    {/* Opções de compartilhamento */}
                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item>
                            <IconButton onClick={handleWhatsAppShare}>
                                <WhatsAppIcon fontSize="large" sx={{ color: '#25D366' }} />
                            </IconButton>
                            <Typography variant="caption" align="center">
                                WhatsApp
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleInstagramShare}>
                                <InstagramIcon fontSize="large" sx={{ color: '#E4405F' }} />
                            </IconButton>
                            <Typography variant="caption" align="center">
                                Instagram
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton>
                                <FacebookIcon fontSize="large" sx={{ color: '#4267B2' }} />
                            </IconButton>
                            <Typography variant="caption" align="center">
                                Facebook
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TikTok;

