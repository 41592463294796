import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WhatsAppPage from './pages/WhatsAppPage';
import LoginPage from './pages/Login';
import Games from './pages/Games';
import Header from './components/Header';
import Footer from './components/Footer';
import Tinder from './pages/Tinder';
import Perfil from './pages/Perfil';
import Conteudos from './pages/Conteudos';
import TikTok from './pages/Tiktok';
function App() {
  const location = useLocation(); // Obtém a localização atual da URL
  const navigate = useNavigate(); // Hook para navegação
  const isLoginPage = location.pathname === '/login'; // Verifica se a página é de login

  // Verifica se o token está presente no localStorage
  useEffect(() => {
    const token = localStorage.getItem('token'); // Verifica o token no localStorage
    if (!token && location.pathname !== '/login') {
      // Se não houver token e não estiver na página de login, redireciona para o login
      navigate('/login');
    }
  }, [location, navigate]);

  return (
    <div>
      {/* Exibe o Header e o Footer somente se não for a página de login */}
      {!isLoginPage && <Header />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/whatsapp" element={<WhatsAppPage />} />
        <Route path="/games" element={<Games />} />
        <Route path="/conteudos" element={<Conteudos />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/tinder" element={<Tinder />} />
        <Route path="/tiktok" element={<TikTok />} />
      </Routes>

      {!isLoginPage && <Footer />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
