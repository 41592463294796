import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const Banner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [userPlan, setUserPlan] = useState(''); // Armazena o plano do usuário
  const [images, setImages] = useState([]); // Armazena as imagens dinâmicas
  const [links, setLinks] = useState([]); // Inicializa os links como um array vazio

  // Função para buscar o plano do usuário
  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario } = data.usuario;
          setUserPlan(plano_do_usuario);
          // Se o plano for Premium, busca imagens e links pagos
          if (plano_do_usuario === 'Premium') {
            fetchImagesAndLinks('https://painelbackend.whatsapchat.com.br/api/banners-pagos');
          } else {
            fetchImagesAndLinks('https://painelbackend.whatsapchat.com.br/api/banners-free');
          }
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  // Função para buscar as imagens e links do endpoint
  const fetchImagesAndLinks = (endpoint) => {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        const fetchedImages = data.map((item) => item.image_url);
        const fetchedLinks = data.map((item) => item.url_redirecionamento); // Atualizado para usar url_redirecionamento
        setImages(fetchedImages);
        setLinks(fetchedLinks);
      })
      .catch((err) => console.error(`Erro ao buscar dados do endpoint ${endpoint}:`, err));
  };

  useEffect(() => {
    fetchUserPlan();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [images]);

  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        py: 6,
        backgroundColor: 'grey.800',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        cursor: links[currentImageIndex] ? 'pointer' : 'default', // Mostra o cursor pointer apenas se houver link
      }}
      onClick={() =>
        links[currentImageIndex] && window.open(links[currentImageIndex], '_blank') // Abre o link em nova aba
      }
    >
      {images.length > 0 ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${images[currentImageIndex]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.8,
            transition: 'background-image 1s ease-in-out',
            borderRadius: '16px',
          }}
        />
      ) : (
        <Box
          sx={{
            color: 'white',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          Carregando banners...
        </Box>
      )}
    </Box>
  );
};

export default Banner;
